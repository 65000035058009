export type ExportDefaultModule<T> = {
    default: T;
};

export type ExecutableModule<T = unknown> = ExportDefaultModule<
    (el?: Element) => T
>;

export type GetExecutableModule = () => Promise<ExecutableModule>;

async function executeModule(
    module: ExecutableModule | Promise<ExecutableModule> | GetExecutableModule,
    el?: Element
): Promise<void> {
    if (typeof module === 'function') {
        module = module();
    }

    (await module).default(el);
}

export default executeModule;
