export type LazyLoadCallback<T = void> = (el: HTMLElement) => T | Promise<T>;

function lazyload(
    selector: string | HTMLElement[],
    callback: LazyLoadCallback,
    _?: never
): void;

function lazyload(
    selector: string | HTMLElement[],
    options: IntersectionObserverInit,
    callback: LazyLoadCallback
): void;

function lazyload(
    selector: string | HTMLElement[],
    options: LazyLoadCallback | IntersectionObserverInit = {
        rootMargin: '250px',
    },
    callback: LazyLoadCallback
): void {
    if (typeof options === 'function') {
        callback = options as LazyLoadCallback;
        options = { rootMargin: '250px' };
    }

    if (!callback) {
        return;
    }

    const nodes =
        typeof selector === 'string'
            ? Array.from(document.querySelectorAll(selector))
            : selector;

    if (nodes.length === 0) {
        return;
    }

    let observed = nodes.length;

    const observer = new IntersectionObserver((entries) => {
        entries
            .filter(({ isIntersecting }) => isIntersecting)
            .forEach((entry) => {
                callback(entry.target as HTMLElement);
                observer.unobserve(entry.target);

                observed -= 1;
            });

        if (observed === 0) {
            observer.disconnect();
        }
    }, options);

    nodes.forEach((node) => observer.observe(node));
}

export default lazyload;
