function createOnLoadPromise() {
    return new Promise<boolean>((resolve) => {
        const load = () => {
            setTimeout(() => resolve(true), 0);
        };

        if (document.readyState === 'complete') {
            load();
            return;
        }

        window.addEventListener('load', load);
    });
}

let promise: Promise<boolean> = null;
export function deferOnLoad(): Promise<boolean> {
    if (promise === null) {
        promise = createOnLoadPromise();
    }

    return promise;
}
