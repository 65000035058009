import executeModule, {
    ExecutableModule,
    GetExecutableModule,
} from './execute-module';
import lazyload, { LazyLoadCallback } from './lazyload';

export function importOnLazyload(
    selector: string,
    importCallback: LazyLoadCallback<ExecutableModule>
): void {
    lazyload(selector, async (el) => {
        executeModule(importCallback(el), el);
    });
}

export function importOnIdle(importCallback: GetExecutableModule): void {
    const cb = () => executeModule(importCallback());

    if (window.requestIdleCallback) {
        window.requestIdleCallback(cb, { timeout: 500 });
    } else {
        setTimeout(cb, 200 + Math.random() * 100);
    }
}

export async function importOnSubscription(
    productIds: string[] | GetExecutableModule,
    importCallback?: GetExecutableModule
): Promise<void> {
    if (typeof productIds === 'function') {
        importCallback = productIds;
        productIds = null as string[];
    }

    const { getSubscription, getUser } = await import('../account');

    const userId = (await getUser())?.userId;

    if (!userId) {
        return;
    }

    const hasAccess = (await getSubscription(userId, productIds))?.hasAccess;

    if (hasAccess) {
        executeModule(importCallback());
    }
    return;
}
